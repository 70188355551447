export function transformBucketsV2ToStd(buckets) {
    return buckets.map(transformBucketV2ToStd);
}
export function transformBucketV2ToStd(bucket) {
    return {
        id: bucket.id,
        date: Number(bucket.date),
        liquidityUSD: Number(bucket.liquidityUSD),
        volumeUSD: Number(bucket.volumeUSD),
        feesUSD: Number(bucket.volumeUSD) * 0.003,
        txCount: Number(bucket.txCount),
    };
}
