import { SUSHISWAP_V2_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { FetchError } from '../../../lib/fetch-error';
import { request } from '../../../lib/request';
import { transformBucketsV2ToStd } from '../../../subgraphs/sushi-v2/transforms/bucket-v2-to-std';
import { transformPoolV2ToBase } from '../../../subgraphs/sushi-v2/transforms/pool-v2-to-base';
import { PoolFieldsFragment } from '../fragments/pool-fields';
import { graphql } from '../graphql';
export const SushiV2PoolBucketsQuery = graphql(`
  query PoolBuckets($id: ID!, $id_Bytes: Bytes!, $block: Block_height, $hourDataFirst: Int = 168, $dayDataFirst: Int = 1000) {
    pair(id: $id, block: $block) {
      ...PoolFields

      poolHourData: pairHourData(first: $hourDataFirst, orderBy: hourStartUnix, orderDirection: desc) {
        id
        date: hourStartUnix
        liquidityUSD: reserveUSD
        volumeUSD: hourlyVolumeUSD
        txCount: hourlyTxns
      }
    }

    poolDayData: pairDayDatas(first: $dayDataFirst, orderBy: date, orderDirection: desc, where: { pairAddress: $id_Bytes }) {
      id
      date
      liquidityUSD: reserveUSD
      volumeUSD: dailyVolumeUSD
      txCount: dailyTxns
    }
  }
`, [PoolFieldsFragment]);
export async function getSushiV2PoolBuckets({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V2_SUBGRAPH_URL[chainId]}`;
    if (variables?.dayDataFirst || 0 > 1000) {
        throw new Error('dayDataFirst must be less than or equal to 1000, paging is not implemented');
    }
    const result = await request({
        url,
        document: SushiV2PoolBucketsQuery,
        variables: {
            ...variables,
            id: variables.id.toLowerCase(),
            id_Bytes: variables.id.toLowerCase(),
        },
    }, options);
    if (result.pair) {
        return {
            ...transformPoolV2ToBase(result.pair, chainId),
            poolHourData: transformBucketsV2ToStd(result.pair.poolHourData),
            poolDayData: transformBucketsV2ToStd(result.poolDayData),
        };
    }
    throw new FetchError(chainId, `Failed to fetch pool ${chainId}:${variables.id}`);
}
