import { FetchError } from '../../../lib/fetch-error';
import { request } from '../../../lib/request';
import { BLOCKS_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { graphql } from '../graphql';
export const BlocksQuery = graphql(`
  query Blocks($first: Int = 1000, $skip: Int = 0, $orderBy: Block_orderBy = number, $orderDirection: OrderDirection = desc, $where: Block_filter) {
    blocks(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      number
      timestamp
    }
  }
`);
export async function getBlocks({ chainId, ...variables }, options) {
    const baseUrl = BLOCKS_SUBGRAPH_URL[chainId];
    if (!baseUrl) {
        throw new FetchError(chainId, `No subgraph URL for chainId ${chainId}`);
    }
    const url = `https://${baseUrl}`;
    const result = await request({ url, document: BlocksQuery, variables }, options);
    return result.blocks.map((block) => ({
        id: block.id,
        number: Number(block.number),
        timestamp: Number(block.timestamp),
    }));
}
